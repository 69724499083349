import React from "react"
import { Link } from "gatsby"

export default function OurExpertise() {
    return (
        <section id="about" className="flashmessage">
            <div className="container" data-aos="fade-up" id="what-is-sterilwize" >
                
                <div className="col-lg-12 mb-6" style={{ backgroundColor: "#08263b", textAlign: "center", display: "contents", height: "150px", border: "none", }}>
                    <div className="card" data-aos="zoom-in" data-aos-delay="100" style={{ backgroundColor: "#08263b", color: "white", fontWeight: 400, }}>
                        <div className="card-body">
                            <h3 className="card-title" >
                                <b style={{ marginTop: "20px", textAlign: "center", }} >
                                    Our Expertise
                                </b>
                            </h3>
                        </div>

                    </div>
                </div>
                <div className="row about-extra">

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    ><br /><br />
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/sapsolution.jpeg"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
                        data-aos="fade-left"
                    >

                        <h3>SAP Solutions</h3>
                        <p>
                            Looking for better ways to run your business. Explore our solutions that meet your business pain points – from Finance, Operations and Manufacturing to Cloud and Security – find solutions that meets your unique business needs.

                        </p>
                        <p>
                            With Custom SAP Solutions, ASAPient supports the key End to End business processes of an organization, Enhance operational efficiency, and gain greater insight with Enterprise Resource Planning (ERP) solutions. We help you unravel the intricacies that the SAP offerings provide and help you make decisions on what is the best product for your business.<br />
                        </p>

                    </div>

                </div>

                <div className="row about-extra">
                    <header className="section-header">

                    </header>

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
                        data-aos="fade-left"
                    >

                        <h3>Business Process Management</h3>
                        <p>
                            Make your organization more agile, improve customer satisfaction and achieve operational savings — all at the same time. With our BPM services, you can quickly automate business processes and increase productivity using proven workflow management tools and integration software packages to create a flexible process platform tailored to your business needs.
                        </p>
                        
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    ><br></br>
                    <br></br>
                    <br></br>
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/BPM.jpeg"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>


                <div className="row about-extra">

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    >
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/CloudComputing.jpeg"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
                        data-aos="fade-left"
                    >

                        <h3>Cloud Computing</h3>
                        <p>
                            Cloud computing is one of the key technologies that is transforming the role of enterprise IT departments from running back office transactional systems of record to running cutting edge real-time systems of engagement.

                        </p>


                    </div>

                </div>

                <div className="row about-extra">
                    <header className="section-header">

                    </header>

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
                        data-aos="fade-left"
                    >

                        <h3>Chatbots</h3>
                        <p>
                            <b>Bot commerce is the future</b> The world loves a good conversation, and bots and skills are opening up ways to communicate directly and more conversationally with your customers, providing them with a more “natural” experience. Bots can also give your business a deeper look into your customers’ experience, including their emotions and sense of urgency during the interactions. This data can help you provide more personalized experiences that create a more meaningful connection with your customers.
                        </p>
                        <p>
                            <b>Conversational commerce: Connecting the dots</b>
                        </p>
                        <p>
                            <b>What is conversational commerce? </b>
                        </p>
                        <p>
                            It’s using AI-infused technology to engage with consumers in a more natural and conversational manner. So, what are you waiting for to get started?
                        </p>
                        <br />
                      
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    >
                        <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                        <img style={{ height: "400px", width: "400px", alignItems: "flex-end" }}
                            src="/assets/img/download/mobilechatbothome.png"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>

                <div className="row about-extra">

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    ><br /><br />
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/ai-brain.png"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
                        data-aos="fade-left"
                    >

                        <h3>Artificial Intelligence</h3>
                        <p>
                            Artificial Intelligence focuses on making already “intelligent” systems capable of simulating human-like decision-making and execution – enabling those systems to perform functions traditionally executed by skilled human professionals – but do so at a much higher level, because of the speed and power available on modern computing platforms.

                        </p>
                        <p>
                            At ASAPient, we provide AI solutions in all industries such as Call-centers, Manufacturing, Healthcare, Real-Estate, Media, Government corporations, Social ventures etc.<br />
                        </p>

                    </div>

                </div>

                <div className="row about-extra">
                    <header className="section-header">

                    </header>

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
                        data-aos="fade-left"
                    >

                        <h3>MDM</h3>
                        <p>
                            ASAPient provides excellent MDM services to ensure you focus on the various project that deliver timely and cost effective. We provide end to end solution including:
                        </p>
                        <ul>
                            <li>
                                Consulting Services
                            </li>
                            <li>
                                Operation Model Design
                            </li>
                            <li>
                                Technology
                            </li>
                            <li>
                                Master Data Quality
                            </li>
                        </ul>
                        
                       
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    >
                        <br></br>
                    <br></br>
                    <br></br>
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/ibm-mdm.png"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>

                <div className="row about-extra">

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    >
                        <br></br>
                    <br></br>
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/blockchain.jpeg"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
                        data-aos="fade-left"
                    >

                        <h3>Block Chain</h3>
                        <p>
                            Blockchain technologies present opportunities for disruptive innovation. It enables global business transactions with less friction and more trust.

                        </p>
                        <p>
                            Accelerate innovation and transform your business networks with blockchain consulting experts. Create and deploy applications in an agile format with cloud technology.<br />
                        </p>

                    </div>

                </div>

                <div className="row about-extra">
                    <header className="section-header">

                    </header>

                    <div
                        className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
                        data-aos="fade-left"
                    >

                        <h3>E Commerce</h3>
                        <p>
                            Our e-commerce specialists help enterprises define their short-term and long-term business goals. We then build for them custom, sustainable e-commerce solutions that seamlessly integrate with their current business processes. With our solid experience backing us, we can turn even the most complex online store into a simplified shopping experience for the customer.
                        </p>

                        <br />
                       
                    </div>
                    <div
                        className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
                        data-aos="fade-left"
                    >
                        <br></br>
                    <br></br>
                        <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
                            src="/assets/img/download/ecommerce.jpeg"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>
              
                

            </div>
        </section>
    )
}
