import React from "react"
import { Link } from "gatsby"

export default function OurIndustries() {
  return (
    <section id="about" className="flashmessage">
      <div className="container" data-aos="fade-up" id="what-is-sterilwize" >
        

        <div className="col-lg-12 mb-6" style={{ backgroundColor: "#08263b", textAlign: "center", display: "contents", height: "150px", border: "none", marginTop: "20px", }}>
          <div className="card" data-aos="zoom-in" data-aos-delay="100" style={{ backgroundColor: "#08263b", color: "white", fontWeight: 400, }}>
            <div className="card-body">
              <h3 className="card-title" >
                <b style={{ marginTop: "20px", textAlign: "center", }} >
                Our Industries
                </b>
              </h3>
            </div>

          </div>
        </div>


        <div className="row about-extra">

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          >
            <br></br>
                    <br></br>
                    <br></br>
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/supplychain.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
            data-aos="fade-left"
          >

            <h3>Supply Chain Management</h3>
            <p>
            Supply Chain Management (SCM) is the flow of products and information between supply chain member organizations. During the various procedures from production to delivery, companies within the chain pay more attention to manage the supply chain and to improve efficiency along with the changing market conditions.

            </p>
           
          </div>

        </div>

        <div className="row about-extra">
          <header className="section-header">

          </header>

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
            data-aos="fade-left"
          >

            <h3>Manufacturing</h3>
            <p>
            We provide solutions to an enterprise of manufacturing that can accomplish:
            </p>
            <ul>
              <li>
              Advanced visibility
              </li>
              <li>
              Flexibility
              </li>
              <li>
              Increased efficiency
              </li>
              <li>
              Enhanced product quality

              </li>
              <li>
              Cost reductions and improved collaboration

              </li>
            </ul>
            <br />
       
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          ><br /><br />
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/manufacturing.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div className="row about-extra">

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          ><br /><br />
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/Health.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
            data-aos="fade-left"
          >

            <h3>Healthcare/Dental</h3>
            <p>
            We offer you software solution with Sterilizing management system. It offers built-in business solution. You can manage your sterilizing the cassette with their status and on which customer which cassette is applied. This application helps to manage cassette records and patient handling.

            </p>
           
          </div>

        </div>

        <div className="row about-extra">
          <header className="section-header">

          </header>

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
            data-aos="fade-left"
          >

            <h3>Banking</h3>
            <p>
            ASAPient deliver customer-centric products and services in Banking. Streamline and automate digital banking operations with an integrated finance, risk, and compliance system to reduce cost and complexity, while driving profitable sales of innovative products and services.
            </p>
            <p>
            We provide solution with complete capabilities of seamlessly managing all banking operations like reporting, analytics, real-time processing of financial data etc.
            </p>
            
            <br />
          
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          ><br /><br />
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/bankingHome.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>


        <div className="row about-extra">

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          ><br /><br />
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/customerServiceSolution.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
            data-aos="fade-left"
          >

            <h3>Customer Service Solutions</h3>
            <p>
            Our customer service solution has its own interface, which many buyers consider an important part of its overall design. The interface may help to make business data more accessible to customer service representatives, sales staff, or anyone else who is working for or assisting the business or organization.

            </p>
            <p>
            Along with providing an accessible clearing house of information, our customer service tool helps users to manage accounts differently to meet customer service goals.

            </p>
           
          </div>

        </div>

        <div className="row about-extra">
          <header className="section-header">

          </header>

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9 sterilwizedef"
            data-aos="fade-left"
          >

            <h3>Retail</h3>
            <p>
            We provide solution that support to manage basic retail function like material procurement, storage, dispatch, etc. It allows the manager to monitor sales of product mix and daily sales volume. It also helps in inventory management.
            </p>
            
            <br />
           
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          ><br /><br />
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/retail.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>


        <div className="row about-extra">

          <div
            className="col-lg-6 order-1 order-lg-2 embed-responsive embed-responsive-16by9"
            data-aos="fade-left"
          ><br /><br />
            <img style={{ height: "350px", width: "500px", alignItems: "flex-end" }}
              src="/assets/img/download/communication.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div
            className="col-lg-6 order-1 order-lg-1 embed-responsive embed-responsive-16by9 bewize"
            data-aos="fade-left"
          >

            <h3>Communications</h3>
            <p>
            We support Communication Industries to set priorities, benchmark progress, improve performance, customers feedback, Increase survey response and reach the people you want to listen from and surveys.

            </p>
          </div>

        </div>
<br></br>
<br></br>
      </div>
    </section>
  )
}
